class SequelNotification extends HTMLElement {
  constructor() {
    super();
    const button = this.querySelector('button') as HTMLElement;
    if (button) {
      button.addEventListener('click', () => {
        this.hide();
      });
    }
  }

  show(title: string, isError = true): void {
    const dialogTitle = this.querySelector('#dialog-title') as HTMLElement;
    const container = this.querySelector('#container') as HTMLElement;
    const icon = this.querySelector('svg[data-icon="error"]') as HTMLElement;
    if (isError && icon.classList.contains('hidden')) {
      icon.classList.remove('hidden');
    }
    if (!isError && !icon.classList.contains('hidden')) {
      icon.classList.add('hidden');
    }
    container.classList.remove('-translate-y-[300%]');
    dialogTitle.textContent = title;
    setTimeout(() => {
      this.hide();
    }, 6000);
  }

  hide(): void {
    const dialogTitle = this.querySelector('#dialog-title') as HTMLElement;
    const container = this.querySelector('#container') as HTMLElement;
    container.classList.add('-translate-y-[300%]');

    setTimeout(() => {
      dialogTitle.textContent = '';
    }, 300);
  }
}

export default SequelNotification;
